const ap_url =
  "https://suntimes-graphics.s3.amazonaws.com/data-storage/elections/2023/general/results/tmp/runoff-results-2023.json";
const interval = 90;
let tick = interval;

const countdownText = (d) => {
  let duration = moment.duration(d * 1000, "milliseconds");
  return `Updated results in: ${duration.minutes()}:${
    duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds()
  }`;
};

const photos = {
  johnson:
    "https://suntimes-graphics-staging.s3.amazonaws.com/2023/February/election-results-homepage/candidate-photos/johnson.png",
  vallas:
    "https://suntimes-graphics-staging.s3.amazonaws.com/2023/February/election-results-homepage/candidate-photos/vallas.png",
};

const colors = {
  vallas: "#ddaaaa",
  johnson: "#a9acca",
};

// const meta = d3.select("#results-homepage #nav").append("section");
// const metaTop = meta.append("div").attr("class", "meta-top");
// const timestamp = metaTop.append("div").attr("class", "live");

const updateMeta = (d) => {
  const date = moment(d).format("LLL");
  timestamp.html(`<span>LIVE</span> ${date}`);
};

// const countdown_timer = metaTop
//   .append("p")
//   .attr("class", "countdown")
//   .text(countdownText(interval));

const norm = (d) => d.split(" ").join("-").toLowerCase();

const container = d3
  .select("#results-homepage .results")
  .append("div")
  .attr("class", "container");

const reporting = d3.select("#results-homepage .reporting");
const rpt_bar = d3.select("#results-homepage .precinct-reporting");

const initData = async () => {
  const response = await fetch(ap_url);
  data = await response.json();
  const mayors = data.races.filter((race) => race.officeName === "Mayor")[0]
    .reportingUnits[0];
  const totalVotes = d3.sum(mayors.candidates, (d) => d.voteCount);
  container.selectAll("*").remove();
  reporting.selectAll("*").remove();
  rpt_bar.selectAll("*").remove();
  reporting
    .append("p")
    .text(
      `${d3.format(",")(mayors.precinctsReporting)} out of ${d3.format(",")(
        mayors.precinctsTotal
      )} precincts reporting`
    );
  // rpt_bar
  //   .append("span")
  //   .text(d3.format(".0%")(mayors.precinctsReporting / mayors.precinctsTotal));
  // rpt_bar
  //   .append("div")
  //   .attr("class", "precinct-reporting-bar")
  //   .append("div")
  //   .style(
  //     "width",
  //     d3.format(".1%")(mayors.precinctsReporting / mayors.precinctsTotal)
  //   );

  // console.log(vallas.voteCount / totalVotes);
  // console.log(mayors.candidates.filter((d) => d.last === "Paul")[0].voteCount);
  vallasVotes = mayors.candidates.filter((d) => d.last === "Vallas")[0]
    .voteCount;
  johnsonVotes = mayors.candidates.filter((d) => d.last === "Johnson")[0]
    .voteCount;

  const runOffText = container.append("div").attr("class", "runoff-txt");

  const runoffPct = container
    .append("div")
    .attr("class", "runoff-pct")
    .style("grid-template-columns", `${vallasVotes}fr ${johnsonVotes}fr`);
  runoffPct.append("div").attr("class", "mid-mark");
  const runoffPct_txt = runoffPct.append("div").attr("class", "txt");
  runoffPct_txt
    .append("p")
    .text(d3.format(".0%")(vallasVotes / totalVotes || 0));
  runoffPct_txt
    .append("p")
    .text(d3.format(".0%")(johnsonVotes / totalVotes || 0));
  const vallas = runOffText.append("div").attr("class", "vallas");
  vallas.append("img").attr("class", "icon").attr("src", `${photos["vallas"]}`);
  const vallas_text = vallas.append("div");
  vallas_text.append("p").text("Vallas");
  vallas_text
    .append("p")
    .html(`<span>${d3.format(",")(vallasVotes)}</span> votes`)
    .attr("class", "votes");

  const johnson = runOffText.append("div").attr("class", "johnson");
  const johnson_text = johnson.append("div");
  johnson_text.append("p").text("Johnson");
  johnson_text
    .append("p")
    .html(`<span>${d3.format(",")(johnsonVotes)}</span> votes`)
    .attr("class", "votes");

  johnson
    .append("img")
    .attr("class", "icon")
    .attr("src", `${photos["johnson"]}`);

  runoffPct
    .append("div")
    .attr("class", "vallas")
    .style("background", colors.vallas);
  runoffPct
    .append("div")
    .attr("class", "johnson")
    .style("background", colors.johnson);

  // mayors.candidates.map((candidate) => {
  //   row = container.append("div").attr("class", "row");
  //   const col = row.append("div").attr("class", "col");
  //   col
  //     .append("img")
  //     .attr("class", "icon")
  //     .style("background-color", colors[candidate.last.toLowerCase()])
  //     .attr("src", `${photos[candidate.last.toLowerCase()]}`);
  //   col.append("p").text(`${candidate.last}`);
  //   row
  //     .append("div")
  //     .attr("class", "pct")
  //     .style("background", colors[candidate.last.toLowerCase()])
  //     .append("span")
  //     .text(
  //       candidate.voteCount
  //         ? d3.format(".1%")(candidate.voteCount / totalVotes)
  //         : "0%"
  //     );
  // });

  updateMeta(data.timestamp);
};

const countdown = () => {
  tick--;
  if (tick < 0) {
    tick = interval;
    initData();
  }
  return countdown_timer.text(countdownText(tick));
};

initData();
// setInterval(countdown, 1 * 1000);
